.navbar {
  transition: $transition;
  color: $white_pure !important;

  @include theme() {
    // color: t('text_color') !important;
    background-color: t('nav_bg') !important;
    box-shadow: t('b_shadow') !important;
  }
  .mob {
    visibility: hidden;
  }

  .moon_icon,
  .sun_icon {
    font-size: 30px;
    margin-right: 15px;
    cursor: pointer;
    transition: $transition;
    @include theme() {
      color: $white_pure;
    }
  }

  a {
    font-size: 14px !important;
    font-weight: 500;
    color: $white_pure;
  }

  .navbar-brand {
    @include displayCenter(center, center);
    @include golText(14px);
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      margin-right: 5px;
    }

    @include mQ($sm) {
      width: 100px;
    }
  }

  .nav__ {
    width: 100%;
    @include displayCenter(center, space-between);
    .navbar__left {
      @include displayCenter(center, space-between);
      // margin: auto;
      a {
        padding: 0 25px !important;
      }
      .active {
        font-weight: bold;
        color: $primary_theme !important;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 3px;
          background: $primary_theme;
          bottom: -22px;
          left: 0;
          border-radius: 3px 3px 0 0;
        }
        @include mQ($lg) {
          &::after {
            display: none;
          }
        }
      }
    }
    .navbar__right {
      .btn_brand {
        padding: 10px 15px !important;
        border-radius: 33px;
        svg {
          margin-right: 10px;
        }
      }
      .user_id {
        @include userAddress(180px);
        padding: 10px !important;
        text-transform: lowercase;
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}

@include mQ($lg) {
  .navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    // a,
    // button {
    //   margin-top: 15px;
    // }
    .mob {
      visibility: visible;
      // position: absolute;
      // right: 0;
    }
    // a {
    //   .navbar-brand {
    //     width: 100px;
    //     margin: auto;
    //   }
    // }
    .navbar-brand {
      margin-left: 50px;
    }

    .nav__ {
      align-items: flex-end;
      .navbar__left {
        margin: 0 !important;
        flex-direction: column;
        align-items: flex-end !important;
        a {
          margin-top: 20px;
        }
      }
      .navbar__right {
        .moon_icon,
        .sun_icon {
          visibility: hidden;
        }
        flex-direction: column;
        align-items: flex-end !important;
        button {
          margin-top: 20px;
        }
      }
    }
  }
}
@include mQ($sm) {
  .navbar {
    .navbar-brand {
      margin-left: 50px;
    }
  }
}
