.roadMap {
	padding: 30px 0 30px 0;
	background-image: url('../../../Assets/LandingPage/Strategy_plan.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	.section_small_heading {
		color: $white_pure !important;
		&:before {
			background: $white_pure !important;
		}
	}
	&_card {
		background: $gradient_six;
		background: #0a1c2a !important;
		border: 2px solid $primary_theme;
		border-radius: 3px;
		height: 100%;
		transition: $transition;
		// @include neonEffectFour(15px);
		max-width: 300px;
		margin: auto;
		.roadMap_card_img {
			margin: auto;
			border-bottom: 1px solid $primary_theme;
			padding: 10px;
			img {
				@include image(contain);
			}
		}

		h4 {
			text-align: center;
			font-weight: 600;
			font-size: 20px;
			color: $white_pure;
		}
		.phase {
			text-transform: uppercase;
			color: $white_pure !important;
			padding: 10px 0;
			font-size: 16px;
			text-align: left;
			padding: 20px 0 0 20px;
		}
		&_items_wrapper {
			padding: 0 10px 0 20px;
			.item_wrapper {
				@include displayCenter(flex-start, flex-start);
				img {
					margin: 3px 10px 0 0;
					width: 15px;
				}
				p {
					padding-bottom: 0px;
					width: 100%;
					color: $white_pure !important;
					font-size: 13px;
					margin-bottom: 10px;
				}
			}
		}
	}
	@include mQ($sm) {
		padding: 0px 0;
		.section_info {
			padding: 15px 0;
		}
		.roadMap_main {
			margin-bottom: 15px !important;
		}
		&_card {
			h4 {
				font-size: 15px;
			}
			.phase,
			p {
				font-size: 12px !important;
			}
		}
	}
}
