.buy__cpt__modal {
  padding: 20px;
  @include mQ($sm) {
    padding: 20px 10px;
  }
  .buy__cpt__header {
    text-align: center;
    &__tile {
      h4 {
        text-align: left;
        font-size: 20px;
        font-weight: 600;
        @include golText(20px);
        @include mQ($sm) {
          font-size: 15px;
        }
      }
    }
    &__close {
      position: absolute !important;
      // right: -15px;
      // top: -12px !important;
      top: 15px;
      right: 15px;
      cursor: pointer;
      min-width: 30px;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: $transition;
      background-color: #000;
      svg {
        color: rgb(255, 255, 255);
        font-size: 20px;
      }
      &:hover {
        svg {
          color: $white_pure;
        }
      }
    }
  }
  &__instructions {
    margin: 30px 0 20px 0;
    @include displayCenter(center, space-between);
    flex-wrap: wrap;
    p {
      margin: 0;
    }
    &__left {
      @include displayCenter(center, center);
      h6 {
        margin: 0;
        margin-left: 10px;
      }
    }
    &__right {
    }
  }
  //   form {
  //     .input__wrapper {
  //       @include displayCenter(center, space-between);
  //       border: 1px solid rgb(221, 221, 221);
  //       border-radius: 5px;
  //       padding: 5px;
  //       margin: 10px 0;

  //       .cpt {
  //         font-size: 13px;
  //         min-width: 90px;
  //         border-right: 2px solid rgba(221, 221, 221, 0.733) !important;
  //         cursor: default !important;
  //         img {
  //           margin-right: 5px;
  //         }
  //       }

  //       //   react-select-

  //       .css-2b097c-container {
  //         min-width: 90px;
  //         .css-yk16xz-control {
  //           border: 0;
  //           background-color: transparent;
  //           box-shadow: none;
  //           font-size: 12px !important;
  //           color: $primary_theme !important;
  //           &:hover {
  //             border-color: transparent;
  //           }
  //         }
  //       }
  //       .css-yk16xz-control,
  //       .css-1pahdxg-control {
  //         border-color: transparent !important;
  //         box-shadow: none !important;
  //         background-color: transparent !important;
  //         border-right: 2px solid rgba(221, 221, 221, 0.733) !important;
  //         border-radius: 0 !important;
  //         font-size: 12px !important;
  //         color: $primary_theme !important;

  //         &:hover {
  //           border-color: transparent !important;
  //           border-right: 2px solid rgba(221, 221, 221, 0.733) !important;
  //         }
  //       }
  //       .css-1wa3eu0-placeholder {
  //         color: $primary_theme;
  //       }
  //       input {
  //         background-color: transparent;
  //         border: 0;
  //         &:focus {
  //           outline: none;
  //         }
  //         &::placeholder {
  //           color: #000;
  //         }
  //       }
  //       .input-group-text {
  //         background-color: transparent;
  //         color: $primary_theme;
  //         font-weight: 500;
  //         user-select: none;
  //         cursor: pointer;
  //         border: 0;
  //         border-left: 2px solid rgba(221, 221, 221, 0.733);
  //       }
  //       .css-1okebmr-indicatorSeparator {
  //         display: none;
  //       }
  //     }
  //     .danger {
  //       font-size: 10px;
  //       text-align: left;
  //       padding: 0;
  //       margin: -5px 0;
  //     }
  //     .buy__btn__wrapper {
  //       text-align: center;
  //       margin-top: 20px;
  //       button {
  //         padding: 8px 60px;
  //       }
  //     }
  //     p {
  //       margin: 0;
  //       margin: 20px 0;
  //       font-size: 12px;
  //       text-align: center;
  //     }
  //   }
}
.buy__token__modal {
  .modal-dialog {
    max-width: 600px;
  }
}

.successModal {
  .success__body {
    // @include displayCenter(center, center);
    // flex-direction: column;
    text-align: center;
    color: $white_pure !important;
    .gol_img {
      width: 150px !important;
      height: 80px !important;
      margin: 0 auto;
      @include image(contain);
    }

    .info {
      font-size: 14px;
      margin: 30px 0 20px 0;
    }
    h4 {
      font-weight: 500;
      margin-bottom: 0;
      color: $white_pure !important;
    }
    h6 {
      font-size: 13px;
      @include displayCenter(center, center);
      flex-wrap: wrap;
      .big {
        font-size: 18px;
        margin: 0 5px;
      }
    }

    .user__id {
      background: linear-gradient(-10deg, #09445e, #281f3b, #07050a);
      position: relative;
      border-radius: 5px;
      color: $white_pure !important;
      p {
        cursor: pointer;
        user-select: none;
        padding: 10px;
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        color: $white_pure !important;
        text-align: center;
        @include userAddress(300px);
        @include mQ($sm) {
          @include userAddress(200px);
        }
        svg {
          color: $white_pure !important;
          margin-left: 10px;
          font-size: 18px;
        }
      }
      &:hover {
        .toolt {
          visibility: visible;
        }
      }
      @include mQ(390px) {
        p {
          font-size: 10px;
        }
      }
    }

    @include mQ($sm) {
      .loader {
        width: 60px;
      }
      h3 {
        font-size: 14px;
      }
      h4 {
        font-size: 14px;
      }
      h6 {
        font-size: 13px !important;
        .big {
          font-size: 13px !important;
        }
      }
    }
  }
}

.wallets {
  .modal-dialog {
    max-width: 430px;
  }
  .buy__cpt__header__tile {
    margin-bottom: 50px;
    margin-bottom: 50px;
    h4 {
      font-size: 20px;
      @include mQ($sm) {
        font-size: 15px;
      }
    }
    @include mQ($sm) {
      margin-bottom: 30px;
    }
  }
  .buy__cpt__header__close {
    top: 17px;
    @include mQ($sm) {
      top: 14px;
    }
  }

  .wallet {
    border-radius: 5px;
    box-shadow: $box-shadow-six;
    padding: 20px;
    margin: 20px 0;
    cursor: pointer;
    transition: $transition;
    @include displayCenter(center, space-between);
    box-shadow: $box-shadow-four;

    img {
      max-width: 30px;
      max-height: 30px;
      //   margin-right: 15px;
    }
    h5 {
      margin: 0;
      font-size: 16px;
      color: $white_pure;
    }
    &:hover {
      box-shadow: $box_shadow_seven;
    }
    @include mQ($sm) {
      padding: 10px;
      margin: 15px 0;
      img {
        max-width: 20px;
        max-height: 20px;
      }
      h5 {
        margin: 0;
        font-size: 14px;
      }
    }
  }
}

.metaMaskNotFound {
  @include mQ($sm) {
    h4 {
      @include golText(20px);
      @include mQ($sm) {
        font-size: 15px;
      }
    }
  }
  .metaMask__body {
    @include displayCenter(center, center);
    flex-direction: column;
    img {
      width: 100px;
      margin-top: 50px;
      @include mQ($sm) {
        width: 50px;
      }
    }
    h5 {
      margin-top: 20px;
      font-size: 20px;
      color: $white_pure !important;
    }
    svg {
      font-size: 50px;
      color: $white_pure !important;
    }
    .download {
      border: 1px solid rgb(196, 196, 196);
      border-radius: 33px;
      padding: 8px 60px;
      transition: $transition;
      color: $white_pure !important;

      &:hover {
        box-shadow: $box_shadow_one;
        transform: translateY(-2px);
      }
    }
    @include mQ($sm) {
      h5 {
        font-size: 16px;
      }
    }
  }
}

.Disconnecct {
  .modal-dialog {
    max-width: 480px;
  }

  .user__id {
    p {
      //   @include userAddress(390px);
      color: $white_pure !important;
      text-align: center;
    }
    @include mQ(450px) {
      p {
        @include userAddress(300px);
        text-align: center;
      }
    }
    @include mQ(350px) {
      p {
        @include userAddress(200px);
        text-align: center;
      }
    }
  }
  .wallet_icon {
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: 50%;
    margin: auto auto 20px auto;
    box-shadow: $box-shadow-seven;
    img {
      @include image(contain);
    }
    @include mQ($sm) {
      width: 30px;
      height: 30px;
      padding: 5px;
      margin: auto auto 10px auto;
    }
  }

  @include mQ($sm) {
    button {
      font-size: 12px;
    }
  }
}

// .modal_fail {
//   .modal-content {
//     @include neonEffectModal(0.3rem, $neon_background_two);
//   }
// }
// .modal_success {
//   .modal-content {
//     @include neonEffectModal(0.3rem, $neon_background_seven);
//   }
// }
// .modal_loading {
//   .modal-content {
//     @include neonEffectModal(0.3rem, $neon_background_eight);
//   }
// }

.create_post_wrapper {
  margin: 30px 0 0 0;

  input {
    width: 100%;
    border: 1px solid $white_pure;
    padding: 15px 15px 15px 25px;
    border-radius: 33px;
    color: $secondary_theme !important;
    font-size: 13px;
    background-color: $white_pure !important;
  }
  .sdf {
    margin-top: 20px;
    text-align: left;
    color: #ffffff !important;
  }
  .upload__field {
    margin-top: 20px;
    color: $white_pure !important;
    aside {
      h6 {
        text-align: left !important;
        width: fit-content;
      }
    }
    p {
      color: $white_pure !important;
    }
    .fileName {
      font-size: 11px;
      text-align: left !important;
    }
    .fileNameError {
      font-size: 11px;
      text-align: left !important;
      color: #dc3545 !important;
    }
  }
  .btn_brand {
    text-transform: capitalize;
  }
  @include mQ($sm) {
    margin: 20px 0 0 0;
    input {
      padding: 8px 5px 8px 10px;
      border-radius: 5px;
    }
  }
}
.sdsfsdc {
  .btn_brand {
    color: #000 !important;
  }
}
