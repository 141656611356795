.feedsBAnner {
	margin-top: 65px;
	overflow: hidden;
	background: linear-gradient(100deg, #09445e, #281f3b, #07050a);
	.section_info {
		.section_heading {
			letter-spacing: 1px;
			font-size: 22px;
		}
	}
	.create_Post {
		padding: 15px;
		margin-bottom: 0px;
		background-image: url('../../Assets/LandingPage/Banner.jpg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		position: relative;
		@include mQ($sm) {
			margin-bottom: 0px;
		}
		&::before {
			position: absolute;
			content: '';
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.637);
		}
		h1 {
			position: relative;
		}
		// .input_wrapper {
		//   width: 100%;
		//   border: 1px solid $white_pure;
		//   padding: 15px 15px 15px 25px;
		//   border-radius: 33px;
		//   cursor: pointer;
		//   color: $white_pure !important;

		//   &::placeholder {
		//     color: $white_pure !important;
		//   }
		//   h6 {
		//     margin: 0;
		//     font-size: 16px;
		//     user-select: none;
		//   }
		// }
		.section_info {
			padding: 30px 0 0 0;
			margin-bottom: 0px;
			position: relative;
			.section_heading {
				@include mQ($sm) {
					font-size: 16px !important;
				}
			}
		}
		.butotnss {
			text-align: center;
			position: relative;

			.btn_brand {
				border-radius: 33px;
				text-transform: capitalize;
			}
		}
	}
}
.scsc {
	margin-bottom: 0 !important;
	background: linear-gradient(100deg, #09445e, #281f3b, #07050a);
}
.dashboard {
	// margin-top: 00px;
	background: linear-gradient(100deg, #09445e, #281f3b, #07050a);
	.container-lg {
		padding-right: 0;
		padding-left: 0;
	}
	.vcs {
		@include golText(15px);
		width: fit-content;
		margin-right: auto;
		border-bottom: 2px solid $primary_theme;
	}
}
