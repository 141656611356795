.footer {
  // margin-top: 520px !important;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  @include theme() {
    background: t('faq_bg_main') !important;
  }
  a {
    @include theme() {
      color: t('text_color') !important;
    }
  }

  padding: 50px 0 10px 0;
  // .imgg {
  //   width: 150px;
  // }
  .dssd {
    @include displayCenter(center, flex-start);
    h2 {
      @include golText(14px);
      margin-bottom: 0;
    }
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      margin-right: 5px;
    }
  }
  .copyright {
    font-size: 13px;
    font-weight: 300;
  }
  .sdsdf {
    text-align: center !important;
  }
  .envelope_wrapper {
    svg {
      font-size: 20px;
      margin-right: 15px;
    }
  }

  @include mQ($md) {
    // margin: auto;
    .c {
      text-align: center !important;
      margin: auto auto 10px auto !important;
      font-size: 12px !important;
      p {
        margin: 0;
      }
      .copyright {
        font-size: 11px !important;
      }
      .dssd {
        justify-content: center;
      }
      .imgg {
        // width: 100px;
      }
    }
  }
}
.underline{
  text-decoration: underline;
}
