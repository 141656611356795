@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

$neon_background_six: linear-gradient(to right, #18dead 20%, #a600e7, #00dfe7);

// gradients
$background_gradient: linear-gradient(180deg, #242831 0%, #212428 100%);
$gradient_one: linear-gradient(
  260.09deg,
  #1ee8b77a 9.85%,
  #ac33bf3d 107.01%,
  #ac33bf3d 20%
);
$gradient_two: linear-gradient(
  90deg,
  #1ee8b7 -0.01%,
  rgba(30, 232, 183, 0) 100%
);
$gradient_three: linear-gradient(90deg, #10171f 3.6%, #214242 105.45%);
$gradient_six: linear-gradient(90deg, #0e151d 3.6%, #233147 105.45%);
$gradient_four: linear-gradient(0deg, #412c4e 0%, rgba(50, 55, 83, 0) 100%);
$neon_background_one: linear-gradient(134deg, #e100ff, #060c21, #18deae);

$neon_background_five: linear-gradient(220deg, #e100ff, #00eeff, #3700ff);
$neon_background_six: linear-gradient(to right, #00eeff, #ff00ea, #fffb00);
$neon_background_seven: linear-gradient(to right, #fffb00, #ff00ea, #00eeff);
$neon_background_eight: linear-gradient(to right, #00ffff, #d8dac3, #6f00ff);

$gradient_five: linear-gradient(
  220deg,
  rgba(30, 232, 182, 0.637) 9.85%,
  rgba(172, 51, 191, 0.4) 107.01%
);

$gradient_five_faq: linear-gradient(
  220deg,
  rgba(30, 232, 182, 0.815) 9.85%,
  rgba(172, 51, 191, 0.747) 107.01%
);

$text_gradient: linear-gradient(to right, #366ce3, #fcac00);
$text_gradient_one: linear-gradient(
  to right,
  #fcac00 35%,
  #00eeff,
  #ff00c8 70%
);
$transition: all 0.5s ease-in-out;

$neon_background_four: linear-gradient(195deg, #e100ff, #00eeff, #3700ff);

$neon_background_one_hover: linear-gradient(268deg, #ffbb00, #d40063);
$neon_background_two: linear-gradient(64deg, #b700ff, #060c21, #18deae);
$neon_background_two_hover: linear-gradient(268deg, #ffbb00, #000000, #d40063);

$neon_background_three: linear-gradient(64deg, #b700ff, #060c21, #18deae);
$neon_background_three_hover: linear-gradient(
  268deg,
  #ffbb00,
  #000000,
  #d40063
);

/* Btn Shadow */
$btn_box_shadow: 0px 0px 30px rgba(245, 222, 91, 0.644);
$btn_box_shadowTwo: 0px 0px 30px rgba(0, 255, 200, 0.692);
$box_shadow_one: rgba(0, 0, 0, 0.685) 0px 10px 15px -3px,
  rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
$box_shadow_two: 0px 1px 4px 3px rgba(0, 0, 0, 0.1);
$box_shadow_three: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
$box_shadow_four: 0px 0px 4px 2px #064e7e;
$box_shadow_five: rgba(0, 0, 0, 0.35) 0px 5px 15px;
$box-shadow-six: rgba(0, 0, 0, 0.16) 0px 1px 4px;
$box_shadow_seven: 0px 0px 4px 2px #ff8800;

// Sass Variables
$primary_theme: #f5de5b;
$theme_bg: rgb(103, 203, 250);
$secondary_theme: #212428;
$white_dull: rgba(255, 255, 255, 0.7);
$white_pure: #ffffffe6;

$light_bg: #161827;
$dark_bg: #161827;
$dark_text: #161827;
$light_text: #ffffffb3;
$navbg_light: #cde4ff;
$navbg_dark: #000000;
$gol_bg: rgb(211, 254, 255);
$buy_gol_bg: rgb(255, 223, 155);
$faq_bg_main: $neon_background_one_hover;
$tokenomics_bg: rgb(40, 17, 51);
$roadmap_bg: rgb(10, 0, 44);
$footer_bg: linear-gradient(
  56deg,
  rgb(255, 180, 172) 0%,
  rgb(255, 180, 172) 40%,
  rgb(103, 145, 134) calc(40% + 1px),
  rgb(103, 145, 134) 50%,
  rgb(38, 78, 112) calc(50% + 1px),
  rgb(38, 78, 112) 70%,
  rgb(255, 235, 211) calc(70% + 1px),
  rgb(255, 235, 211) 100%
);

$graOne: linear-gradient(
  110deg,
  #ffd9e8 4%,
  #ffd9e8 40%,
  #de95ba calc(40% + 1px),
  #de95ba 50%,
  #7f4a88 calc(50% + 1px),
  #7f4a88 70%,
  #4a266a calc(70% + 1px),
  #4a266a 100%
);
$graTwo: linear-gradient(
  230deg,
  #ffd9e8 4%,
  #ffd9e8 40%,
  #de95ba calc(40% + 1px),
  #de95ba 50%,
  #7f4a88 calc(50% + 1px),
  #7f4a88 70%,
  #4a266a calc(70% + 1px),
  #4a266a 100%
);
$graThree: linear-gradient(to right, #c9d6ff, #e2e2e2);
$graFour: linear-gradient(121deg, #ad00ff 0%, #0c0056 100%),
  linear-gradient(121deg, #fa00ff 0%, rgba(0, 255, 71, 0) 100%),
  linear-gradient(127deg, #00f0ff 0%, #a80000 100%),
  radial-gradient(107% 142% at 15% 104%, #f3d0fc 0%, #1700a4 100%),
  radial-gradient(100% 100% at 50% 0%, #7300a9 0%, #00ffe0 100%),
  radial-gradient(100% 100% at 50% 0%, #7300a9 0%, #00ffe0 100%),
  linear-gradient(127deg, #b7d500 0%, #2200aa 100%);
$graFive: linear-gradient(to right, #b993d6, #8ca6db);

//themes

$themes: (
  darkTheme: (
    'bg': $dark_bg,
    'text_color': $light_text,
    'nav_bg': $navbg_dark,
    'faq_item': $secondary_theme,
    'b_shadow': transparent,
    'faq_bg': $gradient_five,
    'faq_bg_main': transparent,
    'gol_bg': transparent,
    'buy_gol_bg': transparent,
    'tokenomics_bg': transparent,
    'roadmap_bg': transparent,
    'footer_bg': transparent,
    'contact_bg': transparent,
  ),
  lightTheme: (
    'bg': $light_bg,
    'text_color': $dark_text,
    'nav_bg': $dark_bg,
    'faq_item': #ffffff,
    'b_shadow': $box_shadow_two,
    'faq_bg': $gradient_five_faq,
    'faq_bg_main': $faq_bg_main,
    'gol_bg': $graTwo,
    'buy_gol_bg': $graOne,
    'tokenomics_bg': $graThree,
    'roadmap_bg': $graFour,
    'footer_bg': $footer_bg,
    'contact_bg': $graFive,
  ),
);

@mixin theme() {
  @each $theme, $map in $themes {
    $theme-map: $map !global;
    .#{$theme} & {
      @content;
    }
  }
  $theme-map: null !global;
}

@function t($key) {
  @return map-get($theme-map, $key);
}

@mixin displayCenter($align, $justify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

// responsive breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// Sass Mixins
@mixin textGradients {
  background-image: $text_gradient_one !important;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}
@mixin displayCenter($align, $justify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin image($object-fit) {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: $object-fit;
}

@mixin mQ($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin userAddress($width) {
  width: $width !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@function setTextColor($color) {
  @if (lightness($color) > 70%) {
    @return $font__black;
  } @else {
    @return $primary;
  }
}

@mixin neonBackgroundOne($angle) {
  $neon_background_one: linear-gradient($angle, #e100ff, #060c21, #18deae);
}
@mixin neonBackgroundTwo($angle) {
  $neon_background_one_hover: linear-gradient(
    $angle,
    #ffbb00,
    #060c21,
    #d40063
  );
}
@mixin neonEffect($borderRadius) {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    z-index: -1;
    background: $neon_background_one;
    @if $borderRadius {
      border-radius: $borderRadius !important;
    }
  }

  &::after {
    position: absolute;
    content: '';
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    z-index: -2;
    // background: $neon_background_one;
    @if $borderRadius {
      border-radius: $borderRadius !important;
    }
  }
  &:hover {
    &::before {
      background: $neon_background_one_hover;
    }
    &::after {
      background: $neon_background_one_hover;
      filter: blur(100px);
    }
  }
}
@mixin neonEffectTwo($borderRadius) {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    z-index: -1;
    // width: calc(100% - 35px);
    // height: calc(100% - 35px);
    @if $borderRadius {
      border-radius: $borderRadius !important;
    }
    background: $neon_background_four;
  }

  &::after {
    position: absolute;
    content: '';
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    z-index: -2;

    background: $neon_background_four;
    @if $borderRadius {
      border-radius: $borderRadius !important;
    }
    filter: blur(5px);
  }
  &:hover {
    &::after {
      background: $neon_background_four;
      filter: blur(300px);
    }
  }
}

@mixin golText($fontSize) {
  font-family: 'Game of Thrones KG';
  font-size: $fontSize;
  color: $primary_theme !important;
}
@mixin neonEffectThree($borderRadius) {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    z-index: -1;
    background: $neon_background_three;
    @if $borderRadius {
      border-radius: $borderRadius !important;
    }
  }

  &::after {
    position: absolute;
    content: '';
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    z-index: -2;
    filter: blur(100px);
    background: $neon_background_three;
    @if $borderRadius {
      border-radius: $borderRadius !important;
    }
  }
  // &:hover {
  //   &::before {
  //     background: $neon_background_three_hover;
  //   }
  //   &::after {
  //     background: $neon_background_three_hover;
  //   }
  // }
}
@mixin neonEffectFour($borderRadius) {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    z-index: -1;
    // width: calc(100% - 35px);
    // height: calc(100% - 35px);
    @if $borderRadius {
      border-radius: $borderRadius !important;
    }
    background: $neon_background_four;
  }

  &::after {
    position: absolute;
    content: '';
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    z-index: -2;

    @if $borderRadius {
      border-radius: $borderRadius !important;
    }
  }
  &:hover {
    &::after {
      background: $neon_background_four;
      filter: blur(5px);
    }
  }
}
@mixin neonEffectModal($borderRadius, $gradient) {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    z-index: -1;
    // width: calc(100% - 35px);
    // height: calc(100% - 35px);
    @if $borderRadius {
      border-radius: $borderRadius !important;
    }
    background: $gradient;
  }

  &::after {
    position: absolute;
    content: '';
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    z-index: -2;

    background: $gradient;
    @if $borderRadius {
      border-radius: $borderRadius !important;
    }
    filter: blur(300px);
  }
  &:hover {
    &::after {
      background: $gradient;
      filter: blur(10px);
    }
  }
}

// end of Mixins
