.portfolio {
  // padding: 50px 0 30px 0;

  // h4 {
  //   font-weight: 500;
  //   color: $white_pure !important;
  //   font-size: 23px;
  //   margin-bottom: 30px;
  // }
  // p {
  //   color: $white_pure !important;
  // }
  .portfolio__container {
    color: $white_pure !important;
    // @include neonEffectModal(10px, $neon_background_eight);
    // background: $secondary_theme;
  }
  .portfolio__cards__container {
    @include displayCenter(center, space-between);
    color: $white_pure !important;
    flex-wrap: wrap;
    .sdfsdfsd {
      border: 2px solid $primary_theme;
      border-radius: 3px;
      padding: 40px 15px 15px 15px;
      height: 100%;
      position: relative;
      .img_gol {
        width: 50px;
        height: 50px;
        position: absolute;
        top: -28px;
        left: 50%;
        transform: translate(-50%);
        img {
          @include image(contain);
        }
      }
    }
    .portfolio__card {
      position: relative;
      // padding: 10px;
      background: $secondary_theme !important;
      width: 350px;
      height: 450px;
      padding: 35px 15px 15px 15px;
      border-radius: 5px;
      margin: 0 10px;
      text-align: center;
      h4 {
        @include golText(20px);
        width: fit-content;
        margin: auto;
        border-bottom: 2px solid $primary_theme;
      }
      h3 {
        color: $white_pure !important;
        font-weight: bold;
        font-size: 30px;
        margin-top: 20px;
      }
      .likesSpace {
        width: 100%;
        height: 25px;
        border-radius: 3px;
        background: #2b3641;
      }
      p {
        color: $white_pure !important;
        max-width: 114px;
        margin: auto;
      }
      .question__icon {
        text-align: right;
        margin-bottom: 10px;
        svg {
          font-size: 22px;
        }
      }
      &__img__wrapper {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: $primary_theme;
        @include displayCenter(center, center);
      }
      .btnt {
        border-radius: inherit !important;
      }
      button {
        margin-top: 20px;
        font-size: 12px !important;
        line-height: 20px !important;
        padding: 6px 10px !important;
        width: 90px !important;
        border-radius: 33px;
      }
    }
    .THIRD {
      .make {
        @include displayCenter(center, space-between);
        flex-wrap: wrap;
      }
    }
  }

  @include mQ($lg) {
    .portfolio__cards__container {
      justify-content: space-evenly;
    }
  }
  @include mQ($md) {
    .portfolio__container {
      padding: 10px;
    }
  }
  @include mQ($sm) {
    h4 {
      font-size: 18px;
      margin-bottom: 20px;
      text-align: center;
    }
    .ff {
      margin: 0;
      margin-top: 5px;
    }
    .ffffff {
      margin: 20px auto 0 auto;
      padding: 7px 10px;
      font-size: 12px;
    }
    .portfolio__cards__container {
      .portfolio__card {
        height: auto;
        h3 {
          font-size: 18px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}
