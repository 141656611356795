.feedWrapper {
	background: linear-gradient(100deg, #09445e, #281f3b, #07050a);
	background-image: url('../../../Assets/LandingPage/FeedsWrapper.jpg');
	background-position: top;
	background-repeat: no-repeat repeat;
	height: 100%;
	min-height: 100vh;
	// background-size: cover;
	.feedWrapper__container {
		max-width: 600px;
		margin: 0 auto;
		background-color: rgb(0, 0, 0);
		padding: 30px 25px;
		// background-color: rgb(172, 172, 172);
	}
	@include mQ($sm) {
		margin-top: 35px;
		padding: 5px;

		.feedWrapper__container {
			padding: 0;
		}
	}
}
