.gol_token {
  padding: 50px 0;
  overflow: hidden;
  // @include theme() {
  //   background: t('gol_bg') !important;
  // }
  // background-color: red;
  background: linear-gradient(180deg, #073447, #281f3b, #07050a);
  position: relative;
  .section_info {
    position: relative;
    z-index: 2;
    margin-bottom: 0px;
    @include mQ($sm) {
      text-align: center;
      padding: 10px 0;
      margin-bottom: 0px;
    }
  }

  .abt_left {
    position: absolute;
    top: 0;
    left: -10%;
    height: 100%;
    display: block;
    object-fit: contain;
  }
  .abt_right {
    position: absolute;
    top: 0;
    right: -10%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
  &_right {
    // background: red;
    // margin: auto;
    text-align: center;
    position: relative;
    z-index: 2;
    .gol_img {
      width: 150px;
      height: 150px;
      margin-bottom: 30px;
      img {
        @include image(contain);
      }
      @include mQ($sm) {
        width: 55px;
        height: 54px;
      }
    }
    height: 100%;
    @include displayCenter(center, center);
    flex-direction: column;
    p {
      text-align: center;
      @include mQ($sm) {
        padding: 0 40px;
      }
    }
    .sdfcsfsd {
      font-weight: 500;
      font-size: 16px;
    }

    .btn_brand {
      border-radius: 33px;
    }
  }

  @include mQ($lg) {
    padding: 20px 0;
    .abt_left {
      left: -30%;
    }
    .abt_right {
      right: -30%;
    }
  }
  @include mQ($sm) {
    padding: 20px 0;
    .abt_left {
      left: -30%;
    }
    .abt_right {
      right: -30%;
    }
  }
  @include mQ(468px) {
    padding: 20px 0;
    .abt_left {
      left: -35%;
    }
    .abt_right {
      right: -35%;
    }
  }
  @include mQ(400px) {
    padding: 20px 0;
    .abt_left {
      left: -40%;
    }
    .abt_right {
      right: -40%;
    }
  }
  @include mQ(370px) {
    padding: 20px 0;
    .abt_left {
      left: -55%;
    }
    .abt_right {
      right: -55%;
    }
  }
}
.section_heading {
  .gols_text {
    color: $primary_theme !important;
    font-size: 34px;
    @include mQ($md) {
      font-size: 27px;
    }
    @include mQ($sm) {
      font-size: 21px;
    }
  }
}
