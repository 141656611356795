/* This stylesheet generated by Transfonter (https://transfonter.org) on March 11, 2018 7:26 PM */

@font-face {
  font-family: 'Game of Thrones KG';
  src: url('GameofThronesKG.eot');
  src: local('Game of Thrones KG'), local('GameofThronesKG'),
    url('GameofThronesKG.eot?#iefix') format('embedded-opentype'),
    url('GameofThronesKG.woff') format('woff'),
    url('GameofThronesKG.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
