.banner {
	min-height: 110vh;
	display: grid;
	place-items: center;
	background-image: url('../../../Assets/LandingPage/Banner.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	overflow: hidden;
	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.411);
	}
	&_center {
		@include displayCenter(flex-start, center);
		flex-direction: column;
		position: relative;
		height: 100%;
		// max-width: 1000px;
		// width: 100%;
		// text-align: center;
		// margin: auto !important;
		background: #050505;
		background: rgba(0, 0, 0, 0.6);
		z-index: 1;

		position: relative;
		&::before {
			position: absolute;
			content: '';
			width: 115px;
			overflow: hidden;
			height: 100%;
			bottom: 0;
			right: 0px;
			background: rgba(0, 0, 0, 0.6);

			transform-origin: bottom;
			transform: skew(-14deg);
			z-index: 1;
		}
		@include mQ($md) {
			&::before {
				display: none;
			}
		}
		p {
			color: $white_pure !important;
		}
		padding: 50px;
		.kir {
			font-weight: bold;
			letter-spacing: 1px;
			@include mQ($sm) {
				letter-spacing: 0px;
				font-size: 10px;
			}
		}
		.section_small_heading {
			margin-left: 60px;
		}
		.section_heading {
			// font-size: 50px;
			margin-bottom: 20px;
		}
		// .intro {
		//   margin-bottom: 20px;
		//   .section_small_heading {
		//     margin-left: 59px;
		//   }
		//   p {
		//     margin-top: 20px;
		//   }
		// }
		.social_txt {
			margin-bottom: 0;
		}
		.social_links {
			@include displayCenter(flex-start, space-between);
			flex-wrap: wrap;
			width: 180px !important;
			margin-top: 20px;
			svg {
				font-size: 22px;
				color: $primary_theme;
				&:hover {
					box-shadow: $btn_box_shadow;
				}
			}
		}
		.banner_buttons {
			@include displayCenter(center, flex-start);
			min-width: 280px;
			margin-bottom: 30px;
			button {
				border-radius: 33px;
				text-transform: capitalize;
			}
		}
	}

	// &_right {
	//   position: relative;
	//   @include displayCenter(center, center);
	//   .robo_coin {
	//     @include image(contain);
	//   }
	// }
	// @include mQ($lg) {
	//   &_left {
	//     @include displayCenter(center, center);
	//     text-align: center;
	//   }
	//   &_right {
	//     display: none !important;
	//     background: tan;
	//   }
	// }

	@include mQ($sm) {
		min-height: 90vh !important;
		padding: 0;
		&_center {
			text-align: center !important;
			padding: 25px 5px;
			.kir {
				margin-left: auto;
				margin-right: auto;
			}
			// .intro {
			//   margin-bottom: 0px;
			//   .section_small_heading {
			//     margin: 0 0 10px 0;
			//   }
			//   h1 {
			//     margin: 30px 0 30px 0;
			//   }
			// }
			.social_txt {
				margin-left: auto;
				margin-right: auto;
			}
			.social_links {
				max-width: 200px;
				margin: 30px auto auto auto;
				svg {
					font-size: 28px;
				}
			}
			.banner_buttons {
				// justify-content: center;
				max-width: 100% !important;
				margin-left: auto;
				margin-right: auto;
				a {
					margin: auto;
				}
			}
			.section_small_heading {
				text-align: center !important;
				margin: 10px auto !important;
			}
			.section_heading {
				text-align: center !important;
				margin: 0 auto 20px auto !important;
			}
		}
	}
}
