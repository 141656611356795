.how_it_works {
  //   background: #000;
  padding: 50px 0 0 0;
  background: linear-gradient(180deg, #073447, #281f3b, #07050a);

  .section_info {
    text-align: center;
    padding: 30px 0 0 0;
    margin-bottom: -50px;
  }
  .hw_card {
    padding: 30px;
    border-radius: 10px;
    // @include neonEffectFour(10px);
    // background: transparent;
    // @include theme() {
    //   background: t('faq_item') !important;
    // }
    height: 100%;
    .icon_bg {
      width: 250px;
      height: 250px;
      margin: auto;
      margin-bottom: -40px;
      img {
        @include image(contain);
      }
      // background-color: $primary_theme;
      @include displayCenter(center, center);
    }
    h5 {
      @include displayCenter(center, center);
      text-align: center !important;
      color: $white_pure !important;
      font-weight: bold;
      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-right: 10px;
        @include mQ($sm) {
          margin-right: 5px;
        }
      }
      margin: 20px 0;
      @include theme() {
        color: t('text_color') !important;
      }
    }

    p,
    a {
      font-size: 14px;
      text-align: center;
      @include displayCenter(center, center);
      @include theme() {
        color: t('text_color') !important;
      }
    }
    a {
      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  @include mQ($sm) {
    padding: 10px 0;
    .hw_card {
      padding: 0 20px;
      margin-top: -20px;
      .icon_bg {
        width: 133px;
        height: 167px;
      }
      h5 {
        color: #ffffff !important;
        @include displayCenter(flex-start, center);
      }
    }
    .rr {
      margin-top: 0 !important;
    }
  }
}
