* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@supports (scrollbar-color: red blue) {
  html,
  body {
    scrollbar-width: thin !important;
    scrollbar-color: #00e1ff transparent;
  }
}

html,
body {
  scroll-behavior: smooth;
  // &::-webkit-scrollbar {
  //   width: auto;
  // }
  // &::-webkit-scrollbar-track {
  //   background: transparent;
  //   margin-block: 0.2em;
  //   border-radius: 100vw;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background: linear-gradient(#00fff2, rgb(195, 0, 255));
  //   border-radius: 100vw;
  //   &:hover {
  //     background: linear-gradient(#fffb00, #00eeff);
  //   }
  // }
}

body {
  font-family: 'Helvetica' !important;
  // background: #242831;
  // background: $background_gradient;
  font-weight: 400;
  scroll-behavior: smooth;
  // background: $secondary_theme !important;
  // color: $white_dull !important;
  @include theme() {
    color: t('text_color') !important;
    background-color: t('bg') !important;
  }
}
.lightTheme {
  color: $dark_text;
  background: $light_bg;
}
.darkTheme {
  color: $light_text;
  background: $dark_bg;
}
// .app {
//   @include theme() {
//     color: t('text_color') !important;
//     background-color: t('bg') !important;
//   }
// }

a {
  text-decoration: none !important;
  color: $white_pure;
}

svg {
  vertical-align: middle !important;
}

// buttons
.btn_brand {
  border: 0;
  background: $primary_theme;
  color: $secondary_theme;
  display: inline-block;
  border-radius: 5px;
  font-weight: 600 !important;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 25px;
  transition: $transition;
  text-transform: uppercase;
  vertical-align: middle !important;
  svg {
    margin-right: 5px;
    vertical-align: middle !important;
    font-size: 18px;
    margin-top: -3px;
  }
  img {
    margin-top: -3px;
    object-fit: contain;
  }
  &:hover {
    box-shadow: $btn_box_shadow;
    background: #f7dc42;
  }
  @include mQ($md) {
    font-size: 14px;
    padding: 8px 15px;
  }
}
.btn_outlined {
  color: $primary_theme;
  background: transparent !important;
  border: 1px solid $primary_theme !important;
  &:hover {
    color: #000;
    background: $primary_theme !important;
    border-color: $primary_theme !important;
  }
}
.btn_secondary {
  border: 0;
  background-color: transparent;
  color: $primary_theme;
  display: inline-block;
  border-radius: 5px;
  font-weight: 600 !important;
  font-size: 15px;
  line-height: 20px;
  padding: 10px;
  transition: $transition;
  text-transform: uppercase;
  svg {
    margin-left: 10px;
    font-size: 20px;
  }
  @include mQ($md) {
    font-size: 14px;
    padding: 8px 25px;
  }
}

.section_info {
  text-align: center;
  padding: 30px 0;
  margin-bottom: 20px;
  @include mQ($sm) {
    margin-bottom: 0 !important;
  }
}

.section_heading {
  font-weight: 600;
  letter-spacing: 3.36px;
  // letter-spacing: 1.5px;
  // font-size: 40px;
  font-family: 'Game of Thrones KG';
  font-size: 25px;
  color: $primary_theme !important;
  line-height: 40px;
  span {
    letter-spacing: 1.36px;
    font-family: 'Helvetica' !important;
    color: $white_pure !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: normal;
  }
  @include mQ($md) {
    font-size: 20px;
  }
  @include mQ($sm) {
    font-size: 16px !important;
    letter-spacing: 1.5px;
  }
}

p {
  font-size: 14px;
  @include theme() {
    color: t('text_color') !important;
  }
  @include mQ($sm) {
    font-size: 12px !important;
  }
}
label {
  @include theme() {
    color: t('text_color') !important;
  }
}
@include mQ($sm) {
  label {
    @include theme() {
      color: t('text_color') !important;
    }
    font-size: 13px !important;
  }
}

.section_small_heading {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
  @include theme() {
    color: t('text_color') !important;
  }

  &:before {
    position: absolute;
    content: '';
    bottom: 3px;
    width: 55px;
    height: 1px;
    @include theme() {
      background-color: t('text_color') !important;
    }
    margin: 0px 0 0 -59px;
  }
}

// modal
.modal-backdrop.show {
  opacity: 0.8 !important;
}

.modal-backdrop {
  background-color: #10171f !important;
}

.modal-content {
  background: linear-gradient(100deg, #09445e, #09445e, #09445e);
  border-radius: 0rem;
}

.modal-dialog {
  margin: 5rem auto !important;

  @include mQ($sm) {
    margin: 5rem 1.3rem !important;
  }
}

// .btn__brand__secondary {
//   background-color: #232d3a !important;
//   color: $primary !important;
//   border-radius: 10px !important;
// }
.btn_brand_disabled {
  // background-color: #acbdd4 !important;
  // color: $white__color !important;
  opacity: 0.7 !important;
  cursor: not-allowed !important;
  box-shadow: none !important;
  // &:hover {
  //   transform: translateY(0) !important;
  // }
}

// tooltip{
.toolt {
  background: $primary_theme;
  color: black;
  padding: 5px 10px;
  visibility: hidden;
  position: absolute;
  font-size: 13px;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  &::after {
    position: absolute;
    content: '';
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;

    border-color: rgb(255, 115, 0) transparent transparent transparent;
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px $secondary_theme;
  }
  to {
    text-shadow: 0 0 10px$primary_theme;
  }
}
.update__spinner {
  animation: spinner linear 1s infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(240deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.update__rotate {
  animation: rotate linear 5s infinite;
}

@keyframes rotate {
  0% {
    transform: perspective(600px) rotateY(0deg);
  }
  // 25% {
  //   transform: perspective(200px) rotateY(90deg);
  // }
  // 50% {
  //   transform: perspective(200px) rotateY(180deg);
  // }
  // 75% {
  //   transform: perspective(200px) rotateY(240deg);
  // }
  100% {
    transform: perspective(600px) rotateY(-360deg);
  }
}
.txt_brand {
  @include theme() {
    color: $primary_theme !important;
  }
}
