.feed_card {
	width: 100%;
	height: auto;
	position: relative;
	// background: red;
	border-radius: 3px;
	border: 2px solid $primary_theme;
	margin-bottom: 60px !important;
	// padding-bottom: 30px !important;
	// border: 1px solid #fafafa;
	.user_info_section {
		padding: 20px 0 0 20px;
	}
	.img_gol {
		width: 50px;
		height: 50px;
		position: absolute;
		top: -28px;
		left: 50%;
		transform: translate(-50%);
		img {
			@include image(contain);
		}
	}
	h5 {
		margin-bottom: 15px;
		font-size: 16px;
		color: $white_pure;
		padding: 20px 0 0 20px;
	}
	&_image {
		padding: 20px;
		width: 100%;
		height: 100%;
		border-radius: 3px;
		cursor: pointer;
		// overflow: hidden;
		img {
			border-radius: 3px;
			@include image(contain);
		}
	}
	&_actions {
		border-top: 2px solid $primary_theme;
		margin-top: 20px;
		padding: 10px;

		@include displayCenter(center, space-between);
		.icon_wrapper {
			@include neonEffectModal(3px, $neon_background_eight);
			border-radius: 3px;
			// background: $secondary_theme;
			// padding: 5px 10px;
			@include displayCenter(center, space-between);
			cursor: pointer;
			margin-right: 15px;
			svg {
				font-size: 25px;
				color: $primary_theme !important;
			}
			span {
				margin-left: 8px;
				font-weight: 500;
				color: $white_pure;
				user-select: none;
				font-size: 11px;
			}
			.brand {
				color: $primary_theme !important;
			}
			&::before {
				top: -1px !important;
				bottom: -1px !important;
				left: -1px !important;
				right: -1px !important;
			}
		}
		// &_left,
		// &_right {
		// }
		// &_left {
		//   @include displayCenter(center, space-between);
		//   .icon_wrapper {
		//     &:last-child {
		//       margin-right: 0;
		//     }
		//   }
		// }
		// &_right {
		//   @include displayCenter(center, space-between);
		//   .icon_wrapper {
		//     &:last-child {
		//       margin-right: 0;
		//     }
		//   }
		// }
	}
	@include mQ(390px) {
		height: auto;
		// &_image {
		//   height: 350px;
		// }
		&_actions {
			&_left,
			&_right {
				.icon_wrapper {
					margin-right: 5px;
					svg {
						font-size: 15px;
					}
					span {
						margin-left: 3px;
						font-size: 11px;
					}
				}
			}
		}
	}
}

.loading_card {
	height: 500px !important;
	border-color: transparent !important;
	margin-bottom: 170px !important;
	@include mQ($sm) {
		margin-bottom: 170px !important;
		height: 300px !important;
	}
	h5 {
		width: 100%;
		height: 30px;
		background: #2b3641;
	}
	&_image {
		// height: 500px !important;
	}
	.feed_card_actions {
		// margin-top: 20px !important;
		border-top: 0 !important;
	}
	.icon_wrapper {
		width: 80px;
		height: 40px;

		&::before,
		&::after {
			display: none;
		}
	}
	.feed_card_image {
		min-height: 400px;
		&::before,
		&::after {
			display: none;
		}
	}
	@include mQ(390px) {
		.icon_wrapper {
			width: 50px;
			height: 30px;
			&::before,
			&::after {
				display: none;
			}
		}
	}
}

.skeleton {
	opacity: 0.7;
	animation: skeleton_loading 1s linear infinite alternate !important;
}

@keyframes skeleton_loading {
	0% {
		background: #2b3641;
	}
	100% {
		background: #778ba0;
	}
}
