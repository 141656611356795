.newsLetter {
  padding: 100px 0;
  background: #0c3e57;
  background: linear-gradient(180deg, #0c3e57, #0a3347, #281f3b);
  position: relative;
  overflow: hidden;

  &_wrapper {
    @include mQ($lg) {
      // .ssss {
      //   order: 0 !important;
      // }
      .sss {
        order: 1 !important;
      }
    }
    position: relative;
    // @include theme() {
    //   background: t('faq_bg') !important;
    // }
    // padding: 10px;
    .section_info {
      text-align: left;
      margin-bottom: 0px;
      padding: 0;
      .section_heading {
        letter-spacing: 2px !important;
        span {
          letter-spacing: normal !important;
        }
      }
    }
    .ewfew {
      text-align: left;
    }
    .msds {
      @include displayCenter(center, center);
      flex-direction: column;
      height: 100%;
      background: #082736;
      position: relative;
      padding: 20px 30px 20px 20px;
      &::before {
        position: absolute;
        content: '';
        width: 115px;
        height: 100%;
        bottom: 0;
        right: 0px;
        background: #082736;
        transform-origin: bottom;
        transform: skew(-16deg);
        z-index: 1;
      }
      @include mQ($md) {
        &::before {
          display: none;
        }
      }
    }
    .newsletter_form {
      @include displayCenter(flex-start, space-between);
      flex-wrap: wrap;
      max-width: 567px;
      margin: auto;

      span {
        color: #dc3545 !important;
        font-size: 11px;
      }
      input {
        color: $white_pure !important;
        padding: 10px 15px;
        // background: transparent;
        background: $gradient_five;
        border-radius: 5px;
        border: 0;
        font-size: 14px;
        font-weight: 500;
        width: 400px;
        height: 50px;
        &::placeholder {
          color: $white_pure !important;
          font-weight: 500;
          font-size: 14px;
        }
      }
      button {
        padding: 15px 35px;
      }
      @include mQ($md) {
        text-align: center;
        max-width: 100%;
        div {
          width: 100%;
        }

        .form-group {
          width: 100% !important;
        }
        input {
          width: 100% !important;
        }
        button {
          width: 100%;
          margin: auto !important;
        }
      }
    }
    .social_links {
      // background-color: red;
      @include displayCenter(center, space-between);
      flex-wrap: wrap;
      max-width: 280px;
      margin-right: auto;
      // float: left;
      padding: 20px 0 0px 0;
      svg {
        color: $primary_theme !important;
        font-size: 32px;
        background: transparent;
        &:hover {
          box-shadow: $btn_box_shadow;
        }
      }
      @include mQ($sm) {
        max-width: 200px;
        svg {
          font-size: 25px;
        }
      }
    }
    .img_s {
      width: 100%;
      height: 300px;
      img {
        @include image(contain);
      }
      @include mQ($sm) {
        width: 100%;
        height: 190px;
      }
    }
  }
  @include mQ($sm) {
    padding: 20px 0;
  }
}
