.whitePaper {
	padding: 50px 0;
	overflow: hidden;
	background-image: url('../../../Assets/LandingPage/dragon_wolf.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	&::before {
		position: absolute;
		content: '';
		top: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, #07344754, #281f3b4d, #07050a6b);
	}

	&_container {
		.section_info {
			position: relative;
			text-align: left;
		}
		position: relative;
		border-radius: 15px;
		padding: 40px 0;

		// .section_info {
		//   h1 {
		//     max-width: 750px;
		//     margin: auto;
		//   }
		// }
		@include mQ($sm) {
			padding: 10px 0;
			.section_info {
				padding: 30px 0 0 0;
				margin-bottom: 0px;
			}
		}
		.whitePaper_info {
			margin-top: 70px;
			p {
				margin-bottom: 20px;
			}
			&_left {
				// background-color: red; // padding: 20px 60px 20px 0;
				margin-top: -20px;
				@include displayCenter(center, space-evenly);
				// flex-wrap: wrap;
				// img{
				//   @include image(contain);
				// }
				.chart_section {
					width: 300px;
					height: 300px;
					margin-right: 10px;
					img {
						@include image(contain);
					}
				}
				.info_section {
					.content {
						@include displayCenter(center, space-between);
						margin-bottom: 10px;
						.color {
							width: 23px;
							height: 28px;
							margin-right: 15px;
						}
					}
				}
				@include mQ($sm) {
					.chart_section {
						width: 130px;
						height: 130px;
					}
					.info_section {
						.content {
							.color {
								width: 15px;
								height: 20px;
								margin-right: 5px;
							}
							.text {
								font-size: 10px;
							}
						}
					}
				}
			}
			&_right {
				padding: 0 60px;
				p {
					margin-top: -10px;
					font-weight: bold;
					@include mQ($sm) {
						margin-top: 0;
					}
				}
				.question {
					position: relative;
					margin-top: 0px;
					img {
						position: absolute;
						width: 25px;
						height: 25px;
						left: -45px;
						object-fit: contain;
						margin-right: 30px;
					}
				}
			}
			@include mQ($xl) {
				&_left {
					padding: 20px !important;
				}
				&_right {
					padding: 20px;
				}
			}
			@include mQ($lg) {
				&_right {
					padding: 20px 40px;
				}
			}
			@include mQ($sm) {
				margin-top: 0px;
				p {
					font-size: 12px;
					margin-bottom: 10px;
				}
			}
		}
	}
	@include mQ($sm) {
		padding: 0;
		&_container {
			.section_info {
				padding: 0;
			}
		}
	}
}
