.liquidity__table {
  border: 1px solid rgba(28, 33, 45, 0.12);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  // .global__filter {
  //   @include displayCenter(center, space-between);
  //   flex-wrap: wrap;
  //   background-color: transparent;
  //   max-width: 550px;
  //   .right {
  //     margin-left: 20px !important;
  //   }
  // }

  .table > tbody > tr:nth-of-type(2n + 2) {
    // background-color: #ffffff !important;
    color: $white_pure !important;
  }
}
.table {
  td {
    padding: 15px 10px;
  }
  border-collapse: separate;
  border-spacing: 0 20px;
  thead {
    th {
      color: $primary_theme !important;
      font-weight: 500;
      text-transform: uppercase;
      border-bottom: 0 !important;
    }
  }
  tbody {
    tr {
      border-radius: 10px !important;
      color: $white_pure !important;
      th {
        color: $white_pure !important;
      }

      td {
        a {
          color: $white_pure !important;
          &:hover {
            text-decoration: underline !important;
          }
        }
        border-top: 1px solid $primary_theme !important;
        border-bottom: 1px solid $primary_theme !important;
        font-size: 14px;
        vertical-align: middle;
        color: $white_pure !important;
        padding: 15px 10px !important;
        &:first-child {
          color: $white_pure !important;
          border-radius: 10px 0 0 10px !important;
          border-left: 1px solid $primary_theme !important;
          max-width: 180px !important;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &:last-child {
          color: $white_pure !important;
          border-radius: 0px 10px 10px 0px !important;
          border-right: 1px solid$primary_theme !important;
          @include mQ($sm) {
            min-width: 200px !important;
          }
        }
      }
    }
  }
}
.table__bottom {
  @include displayCenter(center, space-between);
  flex-wrap: wrap;
  &__left {
    .showing {
      color: $white_pure !important;
    }
  }
  &__right {
    @include displayCenter(center, center);
    button {
      border: 0;
      background-color: $primary_theme;
      min-width: 35px;
      min-height: 35px;
      border-radius: 50% !important;
      margin: 0 5px;
      cursor: pointer;
    }
    .btn__disabled {
      cursor: not-allowed;
    }
    .pagination {
      margin-bottom: 0;
      li {
        &:first-child,
        &:last-child {
          display: none;
        }
        a {
          margin: 0 5px;
          vertical-align: middle;
          min-width: 35px;
          color: $white_dull !important;
          min-height: 35px;
          border-radius: 50% !important;
          @include displayCenter(center, center);
        }
      }
      .circle__active {
        a {
          background-color: $white_pure !important;
          color: $white_pure !important;
        }
      }
    }
  }
}

.table > :not(caption) > * > * {
  color: $white_dull !important;
}
