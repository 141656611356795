.faq {
  padding: 50px 0 150px 0;
  overflow: hidden;
  @include theme() {
    background: #0c3e57;
    // background: t('footer_bg') !important;
  }
  .main {
    display: none;
  }
  .section_info {
    .section_small_heading {
      color: $white_pure !important;
      &:before {
        background: $white_pure !important;
      }
    }
  }

  .faq__containerrrrr {
    margin-top: 50px;
    background: #15181a;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 75px;
      top: -40px;
      background: #15181a;
      transform-origin: top;
      // margin-top: 5px;
      transform: skewY(-2deg);
      z-index: 1;
    }
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 75px;
      bottom: -40px;
      background: #15181a;
      transform-origin: top;
      // margin-top: 5px;
      transform: skewY(2deg);
      z-index: 1;
    }
  }
  .maz {
    width: 100%;
    position: relative;
    z-index: 2;
  }
  .lol {
    @include displayCenter(center, center);
    height: 100%;
    width: 100%;
    flex-direction: column;
  }
  .faq__container {
    // margin-top: 50px;
    // flex-direction: column;
    .faq_containet {
      padding: 15px 25px 7px 25px;

      // box-shadow: $box_shadow_three;
      border-radius: 33px;
      border: 2px solid $white_dull;
      background: $white_pure;
      margin: auto;
      margin-bottom: 20px;
      cursor: pointer;
      width: 100% !important;
    }
    .img_sd {
      width: 100%;
      height: 400px !important;
      position: relative;
      img {
        @include image(contain);
        position: absolute;
        bottom: -120px;
        // background-color: red;
        width: 100%;
        height: 600px !important;
        left: 0;
        right: 0;
        z-index: 2;
      }
      @include mQ($lg) {
        height: 200px !important;
        img {
          bottom: 0px;
          height: 100% !important;
        }
      }
    }
    .faq_item {
      width: 100% !important;

      @include displayCenter(flex-start, space-between);
      div {
        h6 {
          @include theme() {
            color: $secondary_theme !important;
          }
          @include mQ($sm) {
            font-size: 12px;
          }
        }
        svg {
          font-size: 23px;
          color: $secondary_theme !important;
        }
      }
    }
    .collapse {
      p, a {
        color: $secondary_theme !important;
        font-size: 13px;
      }
    }
  }
  @include mQ($lg) {
    .frrfr {
      display: none;
    }
    .rere {
      display: none;
    }
    .main {
      display: block;
      .img_sd {
        display: grid;
        img {
          width: 300px;
          height: 300px;
          margin: auto !important;
        }
      }
    }
  }
  @include mQ($sm) {
    padding: 0px 0;
    .section_info {
      .section_small_heading {
        color: $white_pure !important;
        &:before {
          background: $white_pure !important;
        }
      }
    }
    .faq__container {
      .img_sd {
        height: 200px;
      }
    }
    .main {
      .img_sd {
        img {
          width: 226px;
          height: 258px;
        }
      }
    }
  }
}
