.single_meme {
  // margin-bottom: 1150px !important;
  .feed_card {
    // margin-bottom: 0px !important;
    padding-bottom: 0px !important;
    // border: 0;
    margin-top: 95px;
    height: auto;
    @include mQ(390px) {
      height: auto;
    }
  }
  .create_comment {
    margin: 20px 0 0px 0;
    position: relative;
    &::after {
      filter: blur(5px) !important;
    }
    input {
      border: 2px solid $primary_theme;
      background-color: #000;
      padding: 15px;
      color: $white_pure !important;
      color: $white_pure !important;
      font-size: 12px;
      border-radius: 33px;
      &::placeholder {
        background-color: transparent;
      }
      &:focus {
        background-color: #000;
      }
    }
    .comment_loading {
      cursor: not-allowed;
      opacity: 0.7;
    }
    .send_info_btn {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      border-radius: 50%;
      width: 35px;
      height: 35px;
      display: grid;
      place-items: center;
      border: 0;
      background: $primary_theme;
      svg {
        color: #000;
      }
    }
  }
  .comment_wrapper {
    margin: 20px 0 0px 0;
    background-color: #000;
    border-radius: 5px;
    padding: 10px;
    border: 2px solid $primary_theme;
    height: 100% !important;

    p {
      color: $white_pure !important;
      font-size: 13px;
      margin-bottom: 0 !important;
    }
    .user_info_section {
      @include displayCenter(center, space-between);
      &_left {
        @include displayCenter(center, center);
        .user_image {
          min-width: 23px;
          min-height: 23px;
          background: #000;
          width: 23px;
          height: 23px;
          border-radius: 50%;
          overflow: hidden;
          img {
            border-radius: 50%;
            @include image(cover);
          }
        }
        .user_id {
          margin-left: 10px;
          @include mQ($sm) {
            @include userAddress(200px);
          }
        }
      }
      &_right {
        .icon_wrapper {
          cursor: pointer;
          min-width: 30px;
          min-height: 30px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: grid;
          place-items: center;
          background: $primary_theme;
          svg {
            color: #000;
          }
        }
      }
    }
    .comment {
      margin-left: 40px;
      padding-top: 10px;
    }
    .c_wra {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.user_info_section {
  @include displayCenter(center, space-between);
  margin-bottom: 5px;
  // padding: 20px 0 0 20px;
  &_left {
    @include displayCenter(center, center);
    .user_image {
      min-width: 23px;
      min-height: 23px;
      background: #000;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      overflow: hidden;
      img {
        border-radius: 50%;
        @include image(cover);
      }
    }
    .user_id {
      margin-left: 10px;
      margin-bottom: 0;
      color: $white_pure !important;
      @include mQ($sm) {
        @include userAddress(200px);
      }
    }
  }
}
