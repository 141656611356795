.nft_banner {
  background-image: url('../../../Assets/LandingPage/page-title-bg.jpg');
  height: 50vh;
  // mask-image: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0.137));
  // z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.096);
  }
  @include displayCenter(center, center);
  @include mQ($sm) {
    padding: 10px !important;
    height: 40vh;
  }
  .section_info {
    position: relative;
    .section_small_heading {
      color: $white_pure !important;
      &:before {
        background: $white_pure !important;
      }
    }
  }
}
